import { Fragment } from 'react';

import MergeIcon from './icons/MergeIcon';
import DeployIcon from './icons/DeployIcon';
import SpotahomeLogoIcon from './icons/SpotahomeLogoIcon';
import LoginLeftContainerBackgroundIcon from './icons/LoginLeftContainerBackgroundIcon';
import PullRequestIcon from './icons/PullRequestIcon';
import LoginRightIcon from './icons/LoginRightIcon';
import LoginRightButton from './LoginRightButton';
import LoginFooter from './LoginFooter';

import './LogIn.css';

const LogIn = () => (
  <Fragment>
    <SpotahomeLogoIcon />
    <div className="Login">
      <div className="Login__Left__Container">
        <div className="Login__Left__Features__Container">
          <div className="Login__Left__Feature feat-3">
            <span className="Login__Left__Feature--icon">
              <PullRequestIcon />
            </span>
            CI
          </div>
          <div className="Login__Left__Feature feat-4">
            <span className="Login__Left__Feature--icon">
              <DeployIcon />
            </span>
            Delivery
          </div>
          <div className="Login__Left__Feature feat-4">
            <span className="Login__Left__Feature--icon">
              <MergeIcon />
            </span>
            Experiments
          </div>
        </div>
        <LoginLeftContainerBackgroundIcon />
      </div>

      <div className="Login__Right__Container">
        <LoginRightIcon />
        <div className="Login__Right__Intro">
          <h2 className="Login__Right__Title">Welcome to git it now!</h2>
        </div>
        <div className="Login__Right__Intro">
          <h3 className="Login__Right__Title">Developer Access</h3>
        </div>
        <LoginRightButton />
        <div className="Login__Right__Intro">
          <h3 className="Login__Right__Title">Non Developer Access</h3>
        </div>
        <a className="LogIn__Right__Button" href={`/login/google`}>
          Google
        </a>
      </div>
    </div>

    <LoginFooter />
  </Fragment>
);

export default LogIn;
